:root#light, :root.light {
	color-scheme: light;
}
:root#dark, :root.dark {
	color-scheme: dark;
}
body {
	margin: 0;
	padding: 0;
	font-family: 'Geologica', -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--bg-d2);
	text-rendering: optimizeLegibility;
	accent-color: var(--blue);
	line-height: 1.4;
	min-height: 100vh;
}
body textarea {
	font-family: 'Geologica', -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: "Inconsolata", "Menlo", "Consolas", monospace;
}
b {
	font-weight: 700;
}
h1, h2 {
    color: inherit;
	font-weight: 900;
}
h3 {
    color: inherit;
	font-weight: 700;
}
h4, h5, h6 {
    color: inherit;
	font-weight: 600;
}
img {
	font-size: 0;
}
.input {
	display: block;
	width: 100%;
	padding: 0.25em;
	margin-bottom: 0.5em;
}
.mb {
	margin-bottom: 1em;
}
.p1 {
	padding: 1em;
}
.btn {
	display: inline-block;
	vertical-align: top;
	border-radius: 3px;
	background: transparent;
	font-weight: inherit;
	border: 2px solid;
	text-align: center;
	font-size: 12px;
	padding: 0.5em 1em;
	color: var(--blue);
	margin-right: 0.5em;
	border-radius: 2em;
	height: auto;
	word-break: break-all;
}
.btn.btn-block {
	display: block;
	width: 90%;
	margin: 0.5em auto;
	border-radius: 2em;
}
.btn.btn-link {
	border-color: transparent;
}
.btn-primary {
	color: var(--darkblue);
	font-weight: 700;
}
.btn-sm {
	font-size: 0.8em;
}
.help-btn {
    align-self: center;
	color: var(--darkviolet);
	border: none;
    /* padding: 0;
	font-size: 1.25em;
    width: 1.5em;
    height: 1.5em; */
}
.badge {
	color: #fff;
	background: #333;
	text-transform: uppercase;
	font-weight: 900;
	padding: 0.25em;
	border-radius: 10em;
}
#mask {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background: var(--bg-mask);
	z-index: 11;
}
#sidebar {
	position: fixed;
	top: 60px;
	left: 0;
	bottom: 0;
	width: 240px;
	overflow-y: auto;
	z-index: 9;
}
#insperity {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 240px;
	border-left: 1px solid var(--bg-d2);
}
html.light #insperity {
	border-left: 1px solid var(--bg-l3);
}
#chat {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 240px;
	border-left: 1px solid var(--bg-d2);
}
#right-sidebar {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 240px;
	border-left: 1px solid var(--bg-d2);
}
.dark #right-sidebar {
	background: var(--bg-d1);
}
html.light #right-sidebar {
	border-left: 1px solid var(--bg-l3);
}
#body .body-wrapper {
	color: white;
}
@media screen and (max-width: 1200px) {
	#sidebar {
		width: 80px;
	}
	#sidebar.legacy {
		width: 240px;
	}
	#right-sidebar {
		display: none;
	}
}
@media screen and (max-width: 480px) {
	#sidebar {
		width: 100%;
		max-height: 10em;
		position: relative;
	}
	#body .body-wrapper {
		padding: 6em 1em !important;
	}
	#sidebar.legacy {
		width: 240px;
	}
	#right-sidebar {
		width: 100%;
	}
}
/*OVERWRITES*/
#react-confirm-alert {
	z-index: 2147483647 !important;
}
.react-calendar button {
	color: inherit;
}
/*UTILITY*/
.dont-break-out {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
.noselect {
	-webkit-touch-callout: none !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}
.red {
	color: var(--red);
}
.grey {
	color: var(--txt-lg);
}
/* 404 PAGE */
#fof {
	text-align: center;
	padding: 10% 0;
}
#fof h1 {
	font-size: 64px;
	color: white;
}
#fof h2 {
	color: white;
	text-align: center;
	font-size: 24px;
}
#fof .fof-img {
	margin: 50px 0;
}
#fof h3 {
	color: white;
	text-align: center;
	font-size: 24px;
}
#fof h3:hover {
	cursor: pointer;
	text-decoration: underline;
}
/* IMAGE PAGE */
#img {
	text-align: center;
	padding: 10% 0;
}
#img .error-wrapper h1 {
	font-size: 64px;
	color: white;
}
#img .error-wrapper h2 {
	color: white;
	text-align: center;
	font-size: 24px;
}
#img .img-wrapper {
	z-index: 999999999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
#img .img-wrapper img {
	text-align: center;
	font-size: 0;
}
/* CONFIRMATION MODAL */
.confirm-alert-bg-wrapper {
	/*background: var(--bg-mask) !important;*/
	background: rgba(7, 9, 13, 0.9) !important;
	backdrop-filter: blur(1px);
}
.confirm-alert-bg-wrapper .react-confirm-alert-body {
	border-radius: 3px !important;
}
.confirm-alert-bg-wrapper .react-confirm-alert .react-confirm-alert-body h1 {
	font-size: 18px;
	margin-bottom: 10px;
}
.confirm-alert-bg-wrapper.delete .react-confirm-alert .react-confirm-alert-body h1 {
	color: var(--red);
}
.confirm-alert-bg-wrapper.clone .react-confirm-alert .react-confirm-alert-body h1 {
	color: var(--blue);
}
.confirm-alert-bg-wrapper .react-confirm-alert .react-confirm-alert-body {
	color: var(--txt-dg);
	font-size: 13px;
}
.confirm-alert-bg-wrapper .react-confirm-alert button {
	background: var(--bg-l3);
	border-radius: 3px;
	font-size: 13px;
	padding: 10px 20px;
	color: var(--txt-d);
	transition: background 0.05s, -moz-transform ease-in-out 0.05s;
}
.confirm-alert-bg-wrapper .react-confirm-alert button.red {
	background: var(--red);
	color: white;
}
.confirm-alert-bg-wrapper .react-confirm-alert button.red:hover {
	background: var(--darkred);
	color: white;
}
.confirm-alert-bg-wrapper .react-confirm-alert button.green {
	background: var(--darkgreen);
	color: white;
}
.confirm-alert-bg-wrapper .react-confirm-alert button.green:hover {
	background: var(--darkgreen2);
	color: white;
}
.confirm-alert-bg-wrapper .react-confirm-alert button:hover {
	background: var(--bg-l4);
}
/* LOADING SVG */
.lds-ellipsis {
	position: absolute;
	top: 120px;
	left: calc(50% - 22px);
	width: 44px;
}
.lds-ellipsis.search {
	position: relative;
	top: unset;
	left: unset;
	margin-left: calc(50% - 22px);
	height: 15px;
	margin-top: 20px;
}
.lds-ellipsis div {
	position: absolute;
	top: 0;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: white;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
#light .lds-ellipsis div {
	background: #000;
}
.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.3s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.3s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 20px;
	animation: lds-ellipsis2 0.3s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 32px;
	animation: lds-ellipsis3 0.3s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(12px, 0);
	}
}
.image-zoom-wrapper {
	z-index: 999999999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(7, 9, 13, 0.9);
	backdrop-filter: blur(1px);
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.image-zoom-wrapper:hover {
	cursor: pointer;
}
.image-zoom-wrapper img {
	max-height: 90%;
	max-width: 90%;
	text-align: center;
}
.image-zoom-wrapper img.image-zoom-next {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
}
.image-zoom-options-wrapper {
	position: absolute;
	bottom: 15px;
	height: 30px;
	/*width: 600px;*/
	left: 15px;
	text-align: center;
}
.image-zoom-delete-btn {
	color: white;
	text-align: center;
	height: 30px;
	line-height: 1;
	width: 110px;
	border-radius: 25px;
	background: #3e1318;
	display: inline-block;
	vertical-align: top;
	font-size: 12px;
	transition: background 0.05s, -moz-transform ease-in-out 0.05s;
}
.image-zoom-delete-btn:hover {
	cursor: pointer;
	background: rgba(244, 72, 91, 1);
}
.image-zoom-info-wrapper {
	position: absolute;
	bottom: 15px;
	height: 30px;
	/*width: 600px;*/
	right: 15px;
	text-align: center;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 15px;
	padding: 0 15px;
}
.image-zoom-info-wrapper p {
	color: white;
	line-height: 30px;
	font-size: 12px;
	display: inline-block;
	vertical-align: top;
}
.image-zoom-info-wrapper p span {
	color: var(--txt-lg);
}
.image-zoom-info-wrapper .ex-link {
	color: var(--green);
	margin-left: 5px;
}
.image-zoom-info-wrapper .ex-link:hover {
	cursor: pointer;
	text-decoration: underline;
}
.image-zoom-wrapper .image-arrow {
	position: fixed;
	height: 60px;
	width: 60px;
	top: calc(50% - 30px);
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.05);
	transition: background 0.15s, -moz-transform ease-in-out 0.15s;
	text-align: center;
}
.image-zoom-wrapper .image-arrow:hover {
	background: rgba(255, 255, 255, 0.1);
}
.image-zoom-wrapper .image-arrow span {
	color: white;
	font-size: 30px;
	line-height: 60px;
	font-weight: lighter;
	transition: opacity 0.15s, -moz-transform ease-in-out 0.15s;
	position: relative;
}
.image-zoom-wrapper .image-arrow.left {
	left: 20px;
}
.image-zoom-wrapper .image-arrow.left span {
	right: 1px;
}
.image-zoom-wrapper .image-arrow.right {
	right: 20px;
}
.image-zoom-wrapper .image-arrow.right span {
	left: 1px;
}
#light,
#light body {
	background: var(--bg-l1);
	color-scheme: light;
}
#light .body-wrapper {
	color: var(--txt-dg);
}
#light .card {
	background: #fff;
	color: var(--txt-dg);
}
#light .card .title-container {
	background: #fff;
	color: var(--txt-dg);
}
#light .card p,
#light .card .title span,
#light .card .title span.name {
	color: var(--txt-dg);
}
#light .dz-container {
	background: #fff;
	color: var(--txt-dg);
}
#light h1,
#light h2,
#light h3,
#light h4,
#light h5,
#light h6 {
	color: var(--txt-dg);
}
#light .yellow {
	color: var(--darkyellow);
}
#light .saved {
	color: var(--lt-green);
	animation: field-saved-details-ani-light 1s ease-in-out 1 forwards;
	-webkit-animation: field-saved-details-ani-light 1s ease-in-out 1 forwards;
	-moz-animation: field-saved-details-ani-light 1s ease-in-out 1 forwards;
	-o-animation: field-saved-details-ani-light 1s ease-in-out 1 forwards;
}
#light .changed {
	background: var(--highlight-orange) !important;
	border: 1px solid var(--orange) !important;
}

@keyframes field-saved-details-ani-light {
	0% {
		color: var(--lt-green);
	}
	100% {
		color: var(--txt-dg);
	}
}

#light .react-date-picker__clear-button img {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%235C7B94' viewBox='0 0 30 30'%3E%3Cpath d='M 7 4 C 6.742188 4 6.488281 4.097656 6.292969 4.292969 L 4.292969 6.292969 C 3.902344 6.683594 3.902344 7.316406 4.292969 7.707031 L 11.585938 15 L 4.292969 22.292969 C 3.902344 22.683594 3.902344 23.316406 4.292969 23.707031 L 6.292969 25.707031 C 6.683594 26.097656 7.316406 26.097656 7.707031 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.683594 26.097656 23.316406 26.097656 23.707031 25.707031 L 25.707031 23.707031 C 26.097656 23.316406 26.097656 22.683594 25.707031 22.292969 L 18.414062 15 L 25.707031 7.707031 C 26.097656 7.316406 26.097656 6.683594 25.707031 6.292969 L 23.707031 4.292969 C 23.316406 3.902344 22.683594 3.902344 22.292969 4.292969 L 15 11.585938 L 7.707031 4.292969 C 7.511719 4.097656 7.257812 4 7 4 Z M 7 4 '/%3E%3C/svg%3E");
}

#light .react-datetime-picker__clear-button img {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%235C7B94' viewBox='0 0 30 30'%3E%3Cpath d='M 7 4 C 6.742188 4 6.488281 4.097656 6.292969 4.292969 L 4.292969 6.292969 C 3.902344 6.683594 3.902344 7.316406 4.292969 7.707031 L 11.585938 15 L 4.292969 22.292969 C 3.902344 22.683594 3.902344 23.316406 4.292969 23.707031 L 6.292969 25.707031 C 6.683594 26.097656 7.316406 26.097656 7.707031 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.683594 26.097656 23.316406 26.097656 23.707031 25.707031 L 25.707031 23.707031 C 26.097656 23.316406 26.097656 22.683594 25.707031 22.292969 L 18.414062 15 L 25.707031 7.707031 C 26.097656 7.316406 26.097656 6.683594 25.707031 6.292969 L 23.707031 4.292969 C 23.316406 3.902344 22.683594 3.902344 22.292969 4.292969 L 15 11.585938 L 7.707031 4.292969 C 7.511719 4.097656 7.257812 4 7 4 Z M 7 4 '/%3E%3C/svg%3E");
}

@keyframes rainbow_animation {
	0%,
	100% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
}
@media print {
	* {
		background: #fff !important;
		color: #111 !important;
	}
	.hide-for-print.hide-for-print.hide-for-print {
		display: none !important;
	}
	.full-width-for-print.full-width-for-print.full-width-for-print {
		width: 100% !important;
		max-width: none !important;
		padding: 6em 2em !important;
	}
	img {
		max-width: 100% !important;
	}
	ul,
	img {
		page-break-inside: avoid;
	}
	h1,
	h2,
	h3 {
		page-break-after: avoid;
	}
	@page {
		margin: 2cm;
	}
}
